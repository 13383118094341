import Navbar from "./components/Navbar/Navbar";
import Claim from "./pages/Claim/Claim";

function App() {
  return (
    <>
      <Navbar />
      <Claim />
    </>
  );
}

export default App;
