import React from "react";

import "./styles.css";

function Claim() {
  return (
    <>
      <header className="claim-head">
        <section className="claim-card">
          <a href="https://pub.cryptotems.io" className="btn btn-secondary">
            PUBLIC MINT SITE
          </a>
        </section>
      </header>
    </>
  );
}

export default Claim;
